@import 'bootstrap-variables';
@import 'font-awesome-variables';
@import 'spacers';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../bower_components/font-awesome/scss/font-awesome';
@import '../../bower_components/Yamm3/yamm/yamm.scss';
@import '../../bower_components/owl.carousel/src/scss/owl.carousel';
@import '../../bower_components/owl.carousel/src/scss/owl.theme.default';
// FONTS
$web-font-path: "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i" !default;
@import url($web-font-path);
// SASS FUNCTION
@function em($px, $base: $font-size-base) {
    @return ($px / $base) * 1em;
}

// VARS
$brand-primary: #D40100;
$col-lgray: #DDD;
$col-mgray: #E5E5E5;
$col-gray: #000000;
$col-dgray: #000000;
// LAYOUT
.bg-gray {
    background-color: #F7F7F7;
}

.bg-lgray {
    background-color: $col-lgray;
}

.bg-mgray {
    background-color: $col-mgray;
}

section.section-fluid {
    @media (min-width: $screen-sm-min) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: $screen-md-min) {
        padding-left: 1em;
        padding-right: 1em;
    }
    @media (min-width: 1490px) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: 1810px) {
        padding-left: 12em;
        padding-right: 12em;
    }
}

.disp-inbl {
    display: inline-block;
}

.pos-rel {
    position: relative;
}

// ELEMENTS
.separator {
    display: block;
    max-width: 40px;
    height: 1px;
    margin: 0 auto .5em auto;
    background-color: #000;
    position: relative;
}

#about-team {
    .item-owlslide {
        .single-team {
            text-align: center;
            border: 1px solid #EAEAEA;
            .team-content {
                padding: 1.2em 0.75em;
                background: #EAEAEA;
                .team-position {
                    font-size: em(14px);
                    color: black;
                }
                .team-name {
                    color: #000;
                    margin: 0.5em auto;
                    text-transform: uppercase;
                }
                .team-social a {
                    margin: 0 0.5em;
                }
            }
        }
    }
}

form {
    label {
        font-weight: 400;
        font-size: 80%;
    }
    .form-control {
        &:focus {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(100, 100, 100, .25);
        }
    }
}

.form-control {
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(100, 100, 100, .25);
    }
}

#home-newsletter {
    input {
        border-radius: 25px 0 0 25px;
        border: 1px solid #EAEAEA;
        border-right: 0 none;
        box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        &:focus,
        &:active {
            box-shadow: 0 0 10px rgba(50, 50, 50, .14);
            background-color: $col-dgray;
            color: #FFF;
        }
    }
    .btn {
        padding: 16.5px 32px;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 700;
        color: #000;
        text-transform: none;
        border-radius: 0 25px 25px 0;
        border: 1px solid #EAEAEA;
        box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        &:hover {
            background-color: $col-dgray;
            color: #FFF;
        }
        &:focus,
        &:active {
            outline-color: transparent;
        }
    }
}

#prod-list .utilizzi-top {
    width: 100%;
    float: left;
    .box-utilizzi {
        width: calc((100% - 3em) / 4);
        max-width: 110px;
        display: inline-block;
        background-color: #FAFAFA;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        float: left;
        height: 0;
        padding-bottom: calc((100% - 3em) / 4);
        position: relative;
        margin: .25em .25em .25em 0;
        font-size: em(14px);
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            background-color: #E7E7E7;
            cursor: pointer;
        }
        @media (min-width: $screen-sm-min) {
            width: calc((100% - 3em) / 7);
            padding-bottom: calc((100% - 3em) / 7);
        }
        img {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 10px;
        }
    }
}

#prod-detail .utilizzi-top {
    width: 100%;
    float: left;
    .box-utilizzi {
        width: calc((100% - 3em) / 3);
        max-width: 110px;
        display: inline-block;
        background-color: #FAFAFA;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        float: left;
        height: 0;
        padding-bottom: calc((100% - 3em) / 3);
        position: relative;
        margin: 0.25em;
        &:hover {
            background-color: #E7E7E7;
            cursor: pointer;
        }
        img {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 10px;
        }
    }
}

.table {
    td {
        font-size: em(14px);
        vertical-align: middle !important;
    }
    >tbody>tr>th,
    .table>thead>tr>th {
        color: $col-dgray;
        vertical-align: middle !important;
    }
    &.cart {
        text-align: center;
        >thead>tr {
            >td,
            >th {
                border-bottom-width: 1px;
                color: $col-dgray;
            }
        }
        th {
            text-align: center;
            font-size: em(14px);
        }
        .product-remove a i {
            color: $col-dgray;
        }
        .product-quantity input {
            text-align: center !important;
        }
        .actions {
            .coupon {
                float: left;
            }
        }
    }
    &.cart-total {
        td,
        th {
            padding: 12px;
        }
        tbody>tr>th {
            background-color: #F8F8F8;
            font-size: em(14px);
            width: 50%;
        }
    }
}

#cart {
    h2 {
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
    }
    a.btn-primary {
        @media (min-width: $screen-md-min) {
            float: right;
        }
    }
}

#checkout {
    .panel-primary {
        border-color: #ddd;
        .panel-heading {
            background-color: #FFF;
            border-color: #ddd;
            color: $brand-primary;
            h4 a {
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: #A10100;
                }
            }
        }
        .panel-body {
            border-top-color: #ddd;
        }
    }
}

.news-archive-single {
    .news-img {
        img {
            &:hover {
                opacity: 0.75;
            }
        }
    }
    .news-meta {
        display: block;
        float: left;
        width: 100%;
        @media (max-width: $screen-sm-max) {
            margin-top: 1em;
        }
        .date {
            display: inline-block;
            float: left;
            margin-right: 0.3em;
            &:after {
                content: ' -';
            }
        }
        .category {
            display: inline-block;
            float: left;
        }
    }
    .news-content {
        display: block;
        float: left;
        width: 100%;
        a {
            &:hover {
                text-decoration: none;
            }
            h2 {
                margin-top: 12.5px;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
}

.news-single {
    .news-meta {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.search-archive-single {
    .search-img {
        img {
            &:hover {
                opacity: 0.75;
            }
        }
    }
    .search-meta {
        display: block;
        float: left;
        width: 100%;
        font-size: 14px;
        @media (max-width: $screen-sm-max) {
            margin-top: 1em;
        }
        .category {
            display: inline-block;
            float: left;
        }
    }
    .search-content {
        display: block;
        float: left;
        width: 100%;
        h2 {
            margin-top: 12.5px;
        }
    }
}

// HEADER
.navbar {
    min-height: 100px;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $screen-sm-min) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: $screen-md-min) {
        padding-left: 1em;
        padding-right: 1em;
    }
    @media (min-width: 1250px) {
        min-height: 130px;
        padding-left: 0;
        padding-right: 0;
    }
    @media (min-width: 1490px) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: 1810px) {
        padding-left: 12em;
        padding-right: 12em;
    }
}

.navbar-default .navbar-nav {
    @media (max-width: $screen-sm-max) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    >li {
        >a {
            text-transform: uppercase;
            font-size: em(11px);
            font-weight: 700;
            color: $col-dgray;
            @media (max-width: $screen-sm-max) {
                padding-top: 7px;
                padding-bottom: 7px;
            }
            @media (min-width: 1350px) {
                font-size: em(13px);
            }
            @media (min-width: 1400px) {
                font-size: em(15px);
            }
            &:focus,
            &:hover {
                color: $brand-primary;
            }
        }
        &.active {
            a,
            a:focus,
            a:hover {
                color: $brand-primary;
                background-color: transparent;
            }
        }
    }
    >.open>a {
        &,
        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}

.navbar-toggle {
    margin-top: 30px;
    margin-bottom: 30px;
}

.navbar-brand {
    float: left;
    padding: 25px 15px;
    width: 75%;
    height: 100px;
    @media (min-width: 320px) and (max-width: $screen-sm-max) {
        padding: 15px 15px;
    }
    @media (min-width: $screen-md-min) and (max-width: 1250px) {
        padding: 23px 15px;
        height: auto;
        width: auto;
    }
    @media (min-width: 1250px) {
        padding: 28px 15px;
        height: 130px;
        width: auto;
    }
}

.navbar-main {
    @media (min-width: $screen-md-min) and (max-width: 1250px) {
        float: right;
    }
    >li>a {
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    .yamm-content {
        padding: 0.5em 1em;
        .col-lg-4 {
            @media (min-width: 1200px) and (max-width: 1250px) {
                width: 100%;
            }
        }
        ul {
            a {
                color: #3D3D3D;
                font-size: 14px;
                letter-spacing: 0.25px;
                &:hover {
                    text-decoration: none;
                }
                @media (max-width: 1250px) {
                    font-size: 12px;
                    letter-spacing: 0;
                }
                li {
                    background-color: #F2F2F2;
                    padding: 0.25em 0.5em 0.25em 0.25em;
                    margin-bottom: 0.6em;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background-color: #E7E7E7;
                    }
                    img {
                        margin-right: 0.25em;
                    }
                }
                &:last-of-type>li {
                    margin-bottom: 0;
                    @media (min-width: 1200px) and (max-width: 1250px) {
                        margin-bottom: 0.6em;
                    }
                }
            }
        }
    }
}

.navbar-right>li>a {
    @media (min-width: $screen-sm-min) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media (min-width: $screen-md-min) and (max-width: 1250px) {
        padding-top: 8px;
        padding-bottom: 15px;
    }
}

#header-search {
    &.navbar-form {
        @media (max-width: $screen-sm-max) {
            margin: 0;
            padding-left: 25px;
            padding-right: 25px;
        }
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
            margin-top: 5px;
            margin-bottom: 13px;
        }
    }
    input {
        border-radius: 25px 0 0 25px;
        border: 1px solid #EAEAEA;
        border-right: 0 none;
        box-shadow: 0 0 0 transparent;
        font-size: 12px;
        &:focus,
        &:active {
            box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        }
    }
    .btn {
        padding: 6px 12px;
        font-size: 18px;
        margin: 0;
        text-transform: none;
        border-radius: 0 25px 25px 0;
        border: 1px solid #EAEAEA;
        border-left: 0 none;
        &:focus,
        &:active {
            outline-color: transparent;
        }
    }
}

form#header-lang-form {
    margin: 52px -15px;
    @media (max-width: $screen-sm-max) {
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
    }
    @media (min-width: $screen-md-min) and (max-width: 1250px) {
        margin-top: 10px;
        margin-bottom: 18px;
    }
}

.glossy-select {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 28px;
    padding: 0 12px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #FDFDFD;
    border-radius: 4px;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.04) 51%, rgba(0, 0, 0, 0.02));
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.04) 51%, rgba(0, 0, 0, 0.02));
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.04) 51%, rgba(0, 0, 0, 0.02));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.04) 51%, rgba(0, 0, 0, 0.02));
}

div.circle-avatar {
    width: 30px;
    height: 30px;
    display: block;
    padding-top: 30px;
    border: 1px solid $col-dgray;
    border-radius: 50%;
    background-position: center center;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    &:hover {
        box-shadow: 0 0 10px rgba(50, 50, 50, .25);
    }
}

#breadcrumb-container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    h1 {
        font-size: em(20px);
        margin: 0;
        line-height: 1.42857;
        text-transform: none;
        span {
            color: $col-dgray;
            text-transform: uppercase;
            &:after {
                content: " / ";
                padding: 0 5px 0 10px;
            }
        }
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
        li {
            text-transform: uppercase;
            font-size: em(12px);
            font-weight: 700;
            color: $brand-primary;
            a {
                color: $col-dgray;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
}

// FOOTER
footer {
    color: #FFF;
    .footer-top {
        font-size: em(14px);
        background-color: #525155;
        h4 {
            color: $brand-primary;
        }
        p,
        a {
            color: #FFF;
            letter-spacing: 0.5px;
        }
        ul {
            li {
                margin-bottom: 0.35em;
            }
        }
    }
    .footer-bottom {
        font-size: em(16px);
        background-color: #3D3D3D;
    }
}

// SIDE NAV
.side-nav-box {
    border: 1px solid #EAEAEA;
    padding: 2em 1em;
    margin-bottom: 3em;
    h6 {
        margin: 0;
    }
}

.panel-default {
    border-color: transparent;
    box-shadow: none;
    >.panel-heading {
        background-color: transparent;
        border-color: transparent;
        padding: 5px 0;
        h4 {
            display: inline-block;
            &.panel-title a {
                font-size: em(13px);
                font-weight: 300;
                color: $col-gray;
                &:hover,
                &:active,
                &:focus,
                &.active {
                    text-decoration: none;
                    color: $brand-primary !important;
                    font-weight: 700 !important;
                }
                &.collapsed {
                    color: $col-gray;
                    &.active {
                        color: $brand-primary;
                    }
                }
            }
            img {
                width: 2.5em;
                height: auto;
                margin-right: 0.25em;
            }
        }
        a.toggle-menu {
            display: inline-block;
            float: right;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .panel-body {
        padding-left: 1.0em;
        padding-top: 0;
        ul li {
            a {
                font-size: em(15px);
                font-weight: 400;
                margin-left: 0.35em;
                margin-bottom: 0.5em;
                color: $col-gray;
                &:hover {
                    color: $brand-primary;
                    text-decoration: none;
                }
            }
            &.active {
                a {
                    color: $brand-primary;
                }
            }
        }
    }
}

#box-popular {
    .media-object {
        max-width: 100px;
    }
    .media-heading {
        a {
            font-size: em(12px);
            font-weight: 700;
        }
        p {
            font-size: em(11px);
            line-height: 1.3;
            margin-top: 0.5em;
        }
    }
}

#sidebar-cat {
    ul li {
        padding-left: 1.5em;
        margin-bottom: 0.25em;
        a {
            &:before {
                content: '\f101';
                font-family: 'FontAwesome';
                display: inline-block;
                margin-left: -1.5em;
                width: 1.5em;
            }
        }
    }
}

#side-nav-contacts {
    li {
        margin-bottom: 1em;
        padding-right: 1em;
    }
}

// TYPO
h1,
.h1 {
    text-transform: uppercase;
    color: $brand-primary;
    font-size: em(36px);
    font-weight: 400;
}

h2,
.h2 {
    font-size: em(32px);
    color: #000;
}

h4,
.h4 {
    color: #111;
}

h6,
.h6 {
    text-transform: uppercase;
    color: $col-dgray;
    font-weight: 700;
}

.slash {
    text-transform: uppercase;
    color: $col-dgray;
    font-weight: 700;
    font-size: em(16px);
    margin: 2em 0;
    &:before {
        content: '\005c ';
        color: $brand-primary;
        padding-right: 0.5em;
    }
}

// BUTTONS - CTA
.btn {
    text-transform: uppercase;
    padding: 8px 16px;
    margin: 1em auto;
    font-size: em(12px);
    white-space: normal;
    -webkit-transition: .25s linear;
    -moz-transition: .25s linear;
    -o-transition: .25s linear;
    transition: .25s linear;
    @media (min-width: $screen-md-min) {
        white-space: nowrap;
    }
}

.btn-secondary {
    border: 1px solid #000;
    border-radius: 25px;
    color: $brand-primary;
    font-weight: 700;
    font-size: em(13px);
    padding: 12px 26px;
    &:hover,
    &:active,
    &:focus {
        background-color: #000;
        color: #FFF;
    }
}

// OWL SLIDER
#home-owlslide-main {
    .owl-text-overlay {
        position: relative;
        display: block;
        text-align: left;
        color: white;
        padding: 1em;
        @media (min-width: $screen-sm-min) {
            position: absolute;
            color: white;
            text-align: left;
            width: 85%;
            top: 60%;
            left: 2%;
            transform: translateY(-10%);
            // left: 0;
            // right: 0;
            // margin-left: auto;
            // margin-right: auto;
            color: white;
            &.text-light {
                color: white;
            }
            &.text-dark {
                color: $col-dgray;
            }
        }
        @media (min-width: $screen-md-min) {
            width: 60%;
            // margin-left: 1em;
        }
        @media (min-width: 1490px) {
            width: 50%;
            // margin-left: 3em;
        }
        @media (min-width: 1810px) {
            width: 45%;
            // margin-left: 12em;
        }
        p.owl-caption-top {
            font-size: em(20px);
            font-weight: 300;
            line-height: 1.1em;
            margin-bottom: .4em;
            @media (min-width: $screen-sm-min) {
                font-size: em(32px);
            }
            @media (min-width: $screen-lg-min) {
                font-size: em(44px);
            }
        }
        p.owl-caption-bottom {
            font-size: em(28px);
            font-weight: 250;
            line-height: 1.1em;
            @media (min-width: $screen-sm-min) {
                font-size: em(40px);
            }
            @media (min-width: $screen-lg-min) {
                font-size: em(36px);
            }
        }
    }
}

#home-owlslide-sections {
    .item-owlslide {
        display: table;
        table-layout: fixed;
        clear: both;
        border-bottom: 5px solid $brand-primary;
        background: url('../img/home-owlslide-sections-bg.jpg') no-repeat 50% 50%;
        background-size: cover;
        .img-left-owlslide {
            width: 40%;
            display: table-cell;
            vertical-align: middle;
            padding: 1em;
        }
        .text-right-owlslide {
            width: 60%;
            vertical-align: middle;
            display: table-cell;
            text-align: left;
            padding: 1em 2em 1em 0;
            h3 {
                font-size: em(36px);
                color: #000000;
            }
        }
    }
}

#home-owlslide-applications {
    .item-owlslide {
        display: table;
        table-layout: fixed;
        clear: both;
        background: #000;
        .text-left-owlslide {
            width: 60%;
            display: table-cell;
            text-align: left;
            vertical-align: middle;
            padding: 0.5em 0 0.5em 2em;
            color: #FFF;
            h3 {
                font-size: em(30px);
                text-transform: uppercase;
            }
            p {
                font-weight: 700;
                font-size: em(13px);
                color: #B8B8B8;
            }
        }
        .img-right-owlslide {
            width: 40%;
            display: table-cell;
            padding: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0% 100%;
        }
    }
}

#home-owlslide-news {
    .item-owlslide {
        text-align: center;
        .intro-news {
            position: relative;
        }
        .date {
            border: 5px solid #FFF;
            background-color: #FFC301;
            color: #000;
            max-width: 110px;
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: -15%;
            .day {
                font-size: em(40px);
            }
            .month-year {
                font-size: em(14px);
                letter-spacing: 2px;
                padding-bottom: 0.5em;
            }
        }
        .title {
            margin-top: 5em;
            padding: 0 3em;
            h4 {
                color: #000;
                margin-top: 0;
            }
        }
        .abstract {
            padding: 0.5em 2em;
            color: #000;
            font-size: em(14px);
            letter-spacing: 1px;
            line-height: 1.75em;
        }
    }
}

.owl-theme {
    &#prod-video {
        .owl-nav.disabled+.owl-dots {
            margin-top: 1em;
            text-align: left;
        }
    }
    .owl-nav.disabled+.owl-dots {
        margin-top: 3em;
    }
}

.slider-container {
    position: relative;
    display: block;
    .slider .content img {
        width: 100%;
        height: auto;
    }
    .slider-controls a {
        position: absolute;
        top: 45%;
        z-index: 100;
        color: #333333;
        &.slider-left {
            left: -25px;
        }
        &.slider-right {
            right: -25px;
        }
    }
}

.thumbnail-slider-container {
    margin-top: 5px;
    .owl-stage {
        .owl-item {
            .content {
                padding: 5px;
            }
        }
    }
}

.owl-prev {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 40%;
    left: 0;
    margin-left: -20px;
    display: block !important;
    border: 1px solid black;
    font-size: 24px !important;
    padding: 0 !important;
    color: $col-dgray !important;
    background-color: transparent !important;
    border-radius: 50% !important;
    margin: 0 !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

.owl-next {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 40%;
    right: 0;
    display: block !important;
    border: 1px solid black;
    font-size: 24px !important;
    padding: 0 !important;
    color: $col-dgray !important;
    background-color: transparent !important;
    border-radius: 50% !important;
    margin: 0 !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

// TABS
.nav-tabs>li {
    vertical-align: middle;
    border-right: 2px solid $col-lgray;
    &:last-of-type {
        border: 0 none;
    }
    >a {
        text-transform: uppercase;
        color: $col-gray;
        font-size: em(18px);
        padding-top: 0;
        padding-bottom: 0;
        &:hover {
            cursor: pointer;
        }
    }
    &.active>a,
    &.active>a:active,
    &.active>a:focus {
        color: $brand-primary;
    }
}

// ITEMS
.item {
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    min-height: 330px;
    padding-left: 30px;
    padding-right: 30px;
}

.item-content {
    bottom: 0px;
    min-height: 110px;
}

// BACK TO TOP
#top {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: $brand-primary;
    bottom: 12px;
    right: 20px;
    cursor: pointer;
}

#top #top-arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 5px solid #fff;
    border-left: 5px solid #fff;
    border-bottom: none;
    border-right: none;
    width: 10px;
    height: 10px;
    margin: 15px auto;
}


/* Forso una larghezza minima alle ultime colonne della tabella di crudbooster per non accavallare i bottoni */

#table_dashboard>thead>tr>th:last-of-type {
    min-width: 130px;
}

.news-gallery {
    margin-top: 50px;
}

.title-team-group {
    margin-bottom: 20px;
    font-weight: 300;
}

.img-team {
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.img-product {
    height: 84px;
}

.social-link {
    margin-top: 20px;
    font-size: 18px;
}

.home_button_slide_owl {
    bottom: 10px;
    position: absolute;
}

.home_title_slide_owl {
    margin-bottom: 50px;
}

.menu-header>li>a {
    padding-top: 52.5px;
    padding-bottom: 52.5px;
    padding-left: 10px;
    padding-right: 10px;
}

.logo-ferrari {
    /*  max-width: 240px;
    padding-top: 35px; */
}

a:focus {
    outline: 0 !important;
}